@import "@alltrails/shared/styles/landsEnd.scss";
.listItem {
  @include text-200;
  color: var(--color-text-primary-inverse);
  margin-bottom: var(--space-200);

  a {
    color: var(--color-text-primary-inverse);
    display: inline;
    &:hover {
      text-decoration: underline;
    }
  }
}

.titleLink.titleLink {
  @include heading-300;
  color: var(--color-text-primary-inverse);
}

@import "@alltrails/shared/styles/landsEnd.scss";
.socialIcons {
  @include flex-row;
  align-items: center;
  gap: var(--space-300);
  flex-wrap: wrap;
}

.hoverClass:hover {
  svg {
    g {
      fill: var(--color-text-primary-inverse);
    }
  }
}

.title {
  @include text-300-bold;
  margin-bottom: var(--space-100);
  white-space: nowrap;
  color: var(--color-text-primary-inverse);
}

@include minWidth($breakpoint-768) {
  .title {
    margin-bottom: var(--space-300);
  }
}

@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  width: 100%;
}

.title {
  @include text-300-bold;
  color: var(--color-text-primary-inverse);

  a:hover {
    color: var(--color-text-primary-inverse);
  }
}

.title,
.childLink {
  margin-bottom: var(--space-200);

  a {
    color: var(--color-text-primary-inverse);
  }
}

.childLink {
  @include text-200;
  color: var(--color-text-primary-inverse);

  a:hover {
    text-decoration: underline;
    color: var(--color-text-primary-inverse);
  }
}
